import React, { useState, useEffect, useRef } from 'react';
import Carousel from '../../Carousel/carousel';
import './home.css';
import backgroundImage from '../../../Assets/Images/bg-txt.jpg'; 
import Testimonials from '../../Testimonials/testimonials';
import Banner from '../../Banner/banner';

// Icons images
import icon1 from '../../../Assets/Icons/icon-1.png'; 
import icon2 from '../../../Assets/Icons/icon-2.png';
import icon3 from '../../../Assets/Icons/icon-3.png';

// opportunity imgaes
import educationImage from '../../../Assets/Images/education.png';
import amenitiesImage from '../../../Assets/Images/education2.jpg';
import impactImage from '../../../Assets/Images/impact.png';


// Projects images
import projectFeed250 from '../../../Assets/Images/Projects/ProjectFeed250.jpg';
import projectImpact from '../../../Assets/Images/Projects/ProjectImpact.jpg';
import finishedProject2 from '../../../Assets/Images/Projects/finished-project-2.jpg';
import Loader from '../../Loader/loader'; 

const Home = () => {
  const [loading, setLoading] = useState(true); 
  const textRef = useRef(null); // Reference for the animated text

  // Function to animate text
  const animateText = () => {
    const textElement = textRef.current; // Get the referenced element
    if (textElement) {
      const text = textElement.textContent;
      textElement.innerHTML = '';

      // Loop through each character and wrap in a span
      for (let i = 0; i < text.length; i++) {
        const span = document.createElement('span');
        span.textContent = text[i];
        span.style.setProperty('--i', i);
        textElement.appendChild(span);
      }
    }
  };

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false); 
      animateText(); 

  
      const interval = setInterval(() => {
        animateText(); 
      }, 10000);

      return () => clearInterval(interval);
    }, 3000); 

    
    return () => clearTimeout(loadingTimeout);
  }, []);


  return (
    <div className="home">
      {loading ? (
        <Loader /> // Show the loader while loading
      ) : (
        <div className="home-container">
          <Carousel />
          {/* texture background start */}
          <div className="background-texture-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="container text-container">
              {/* Add any content you want to overlay on the background */}
            </div>
          </div>
          {/* texture background End */}

          {/* service & mission Start */}
          <div className="container-xxl py-5 bg-image" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="container">
              <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                <h1 className="display-6 mb-5 fw-bolder">What We Do</h1>
              </div>
              <div className="row g-4 justify-content-center">
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                    <img className="img-fluid mb-4" src={icon1} alt="Mission Icon" />
                    <h4 className="mb-3 m-f">Mission</h4>
                    <p className="mb-4">Little Star Charity is dedicated to empowering today's children to assume their rightful positions as the leaders of tomorrow.
                       Our mission is to ensure they have access to food, shelter, education, opportunities, and life-altering experiences necessary for their success and to help create a better world for all.</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                    <img className="img-fluid mb-4" src={icon2} alt="Vision Icon" />
                    <h4 className="mb-3 m-f">Vision</h4>
                    <p className="mb-4">Creating a world where every child has the opportunity to thrive and reach their full potential.</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                  <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                    <img className="img-fluid mb-4" src={icon3} alt="Strategy Icon" />
                    <h4 className="mb-3 m-f">Strategy</h4>
                    <p className="mb-4">We empower communities through sustainable development, education, and health initiatives, fostering self-reliance and long-term growth.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* service & mission End */}

          {/* Programmes Start */}
          <div className="container-xxl bg-light my-5 py-5 our-impacts  ">
            <div className="container py-5">
              <div className="text-center mx-auto mb-5 wow fadeInUp" style={{ maxWidth: '900px' }}>
                <h1 className="display-6 mb-5 fw-bolder">Every Child Deserves The Opportunity To Learn</h1>
              </div>
              <div className="row g-4 justify-content-center">
                {/* Education Programme */}
                <div className="col-lg-4 col-md-6 wow fadeInUp" style={{ animationDelay: '0.3s' }}>
                  <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                    <div className="text-center p-4 pt-0">
                      <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                        <small>Education</small>
                      </div>
                      <h5 className="mb-3 m-f">Quality Education for the African Children</h5>
                      <p>We are committed to providing learning opportunities and resources to foster growth, skill development, and lifelong learning.</p>
                    </div>
                    <div className="position-relative mt-auto">
                      <img className="img-fluid" src={educationImage} alt="Basic Amenities" />
                      <div className="causes-overlay">
                        <a className="btn btn-outline-primary" href="#">
                          Read More
                          <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                            <i className="fa fa-arrow-right"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Basic Amenities */}
                <div className="col-lg-4 col-md-6 wow fadeInUp" style={{ animationDelay: '0.3s' }}>
                  <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                    <div className="text-center p-4 pt-0">
                      <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                        <small>Basic Amenities</small>
                      </div>
                      <h5 className="mb-3 m-f">Provision of Food, Shelter, and Clothing</h5>
                      <p>Access to essential resources is fundamental. We ensure that communities receive the basic amenities they need, including food, clean water, and shelter, to support their well-being and improve quality of life.</p>
                    </div>
                    <div className="position-relative mt-auto">
                      <img className="img-fluid" src={amenitiesImage} alt="Basic Amenities" />
                      <div className="causes-overlay">
                        <a className="btn btn-outline-primary" href="#">
                          Read More
                          <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                            <i className="fa fa-arrow-right"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Impact Programme */}
              
                <div className="col-lg-4 col-md-6 wow fadeInUp " style={{ animationDelay: '0.5s' }}>
                  <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                    <div className="text-center p-4 pt-0">
                      <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                        <small >Impact</small>
                      </div>
                      <h5 className="mb-3 m-f">Creating a Sustainable Future</h5>
                      <p>Little star is dedicated to making positive change that uplifts and transforms lives. Our focus is on nurturing the next generation of leaders who will drive positive change in their communities and beyond.</p>
                    </div>
                    <div className="position-relative mt-auto">
                      <img className="img-fluid" src={impactImage} alt="Impact" />
                      <div className="causes-overlay">
                        <a className="btn btn-outline-primary" href="#">
                          Read More
                          <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                            <i className="fa fa-arrow-right"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Programmes End */}



          {/* Donation Banner Start */}
          <div className="App">
      <Banner />
    </div>
    {/* Donation Banner End */}




        {/* Upcoming Project Start */}

        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto mb-5" style={{ maxWidth: '500px' }}>
                <h1 className="display-6 mb-5 fw-bolder">
                        <span ref={textRef} className="animated-text">Upcoming Projects</span>
                    </h1>
                </div>
                <div className="row g-4">
                    {/* Coming Soon Project */}
                    <div className="col-lg-6 col-md-12">
                        <div className="project-item pipeline shadow h-100">
                            <img
                                className="img-fluid"
                                src={projectFeed250}
                                alt="Pipeline Project"
                            />
                            <div className="project-text p-4">
                                <h4 className="mb-3">Project Feed 250</h4>
                                <p>
                                    Our most ambitious project yet. Stay tuned for groundbreaking
                                    innovations.
                                </p>
                                <button className="btn btn-coming-soon mt-3">Coming Soon</button>
                            </div>
                        </div>
                    </div>

                    {/* Upcoming Project 1 */}
                    {/* <div className="col-lg-3 col-md-6">
                        <div className="project-item upcoming shadow h-100">
                            <img
                                className="img-fluid"
                                src={projectImpact}
                                alt="Upcoming Project 1"
                            />
                            z
                            <div className="project-text p-4">
                                <h5 className="mb-3">Project Impact</h5>
                                <p>One Event, One Day, Different Places.</p>
                            </div>
                        </div>
                    </div> */}

                    {/* Finished Project 2 */}
                    <div className="col-lg-3 col-md-6">
                        <div className="project-item finished shadow h-100">
                            <div className="project-image-container-finish">
                                <img
                                    className="img-fluid"
                                    src={projectImpact}
                                    alt="Finished Project 2"
                                />
                                <div className="overlay">
                                    <span className="finished-sign">Finished Project</span>
                                </div>
                            </div>
                            <div className="project-text p-4">
                                <h5 className="mb-3">Project Impact</h5>
                                <p>One Event, One Day, Different Places.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="project-item finished shadow h-100">
                            <div className="project-image-container-finish">
                                <img
                                    className="img-fluid"
                                    src={finishedProject2}
                                    alt="Finished Project 2"
                                />
                                <div className="overlay">
                                    <span className="finished-sign">Finished Project</span>
                                </div>
                            </div>
                            <div className="project-text p-4">
                                <h5 className="mb-3">The Porters Village</h5>
                                <p>Home provides care and support for Orphaned and Vulnerable Children </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Upcoming Project End */}


          <Testimonials />
        </div>
      )}
    </div>
  );
};

export default Home;
