import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import Font Awesome Icon component
import { faCalendarAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons'; // Import specific icons
import './ProjectDetails.css'; // Ensure to include the updated CSS

import project1 from '../../Assets/Images/Projects/ProjectFeed250.jpg';
import project2 from '../../Assets/Images/Projects/ProjectImpact.jpg';
import project3 from '../../Assets/Images/Projects/finished-project-2.jpg';
import project4 from '../../Assets/Images/Projects/finished-project-3.jpg';
import project5 from '../../Assets/Images/Projects/Trinity Home Academy.png';

const projectData = [
  { 
    id: 1, 
    title: 'Project Feed 250', 
    description: 'Details about Project Feed 250.', 
    image: project1,
   
  },
  { id: 2, title: 'Project Impact', description: 'One event, same time, different locations.', image: project2,  details: [
    'Project Impact (Mother love hospital Adenta) - A healthy and fruits talk with the children’s ward at mother love hospital. 30 children in total. Would provide toys for the really little children.',
    'Project Impact (The caring hands of God Home, Kwabenya) - We are going to visit a home at Kwabenya. They have 25 children in total. Going to spend the day with them, give them food, and have fun. We will do bead making, painting and have impactful conversations.',
    'Project Impact (Disability village Adenta) - We’re visiting some special kids at their home; about 10 of them, and providing them with toiletries and other items. We’ll engage them by doing some paintings.',
    'Project Impact (Street children American house) - Giving out snacks at the American house in East Legon. Hoping to talk to as many people as we can in that area, and see how we can help more.',
    'Project Impact (Haatso community event) - Gathering at an event centre to spend time with the children in Haatso. Fun and games included with some snacks.',
    'Project Impact (Abokobi Street children) - My sister and her friends and I are engaging with street children in Abokobi.',
    'Project Impact (Working with an NGO) - Working with somebody in Ukraine by teaching him English in case of immigration opportunities and support due to the war.',
    'Project Impact (Street children at Accra Mall) - Giving snacks to children at Accra Mall and having impactful conversations with them.',
  ] },
  { id: 3, title: 'Potters Village Orphanage', description: 'Feeding the needy through community food drives.', image: project3, details:[ 'We visited the Potters Village house, we had meaningful conversations with the children, sent about 50 children to the playground so they can have fun. We provided food and educational materials.' ]},
  { id: 4, title: 'New life Orphanage', description: 'We fed approximately 170 children at the new life Orphanage in Nungua.', image: project4, details: ['We fed approximately 170 children at the new life Orphanage in Nungua. Spent some time with the children; engaging in fun activities and feeding them once more with knowledge and the Word of God.'] },
  { id: 5, title: 'Trinity Home Academy (Tutu)', description: 'Inaugural project', image: project5, details: ['First project out of high school. The initial idea was to reach out to the orphanages which are not visited or donated to as often. We ended up feeding approximately 80 children with food, knowledge and the Word of God.'] }
];

const ProjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  // Find project by ID, or return `undefined` if not found
  const project = projectData.find(p => p.id === parseInt(id));
  
  // If project is not found, return an error message
  if (!project) {
    return <div>Project not found!</div>;
  }

  const addToCalendarLink = "https://calendar.google.com/calendar/u/0/r/eventedit?text=Project%20Feed%20250&dates=20241016T080000Z/20241016T090000Z&details=Join%20the%20Project%20Feed%20250%20event&location=&sf=true&output=xml";
  
  return (
    <div className="project-details-container">
      <div className="founder-image">
        <img src={project.image} alt={project.title} />
      </div>
      <div className="project-info">
        <h1>{project.title}</h1>
        <h3>Description</h3>
        <p>{project.description}</p>
        
        <h3>Project Details</h3>
        {project.details && project.details.length > 0 ? (
          <ul className="project-details-list">
            {project.details.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
        ) : (
          <p>No detailed information available for this project.</p>
        )}
        
        <div className="button-container">
          {project.id === 1 && (
            <a href={addToCalendarLink} className="action-button" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faCalendarAlt} className="button-icon" /> Add to Calendar
            </a>
          )}
          <button className="back-button" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} className="button-icon" /> Back to Our Impact
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
