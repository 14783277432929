import React from 'react';
import './Footer.css';
import { FaTwitter, FaFacebookF, FaArrowRight, FaEnvelope, FaLinkedinIn} from 'react-icons/fa';
import logo from '../../Assets/Logos/LittleStar-logo.png';
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <div className="footer-container">
      <div className="container-fluid bg-dark text-white-50 footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeIn' }}>
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6 footer-logo">
              <span >  <img src={logo} alt="Logo" className="me-2" style={{ width: 45, height: 40 }} /> </span>
              <span> <h1 className="fw-bold text-footer m-0 fs-8">LITTLE STAR</h1></span>
              <span> <h5 className=" charity text-footer m-0 fs-5" >CHARITY</h5></span>


            </div>

            <div className="col-lg-3 col-md-6">
              <h5 className="text-light mb-4 m-f">Address</h5>
              <a href="https://www.google.com/maps/place/Accra,+Ghana" className="no-underline d-block mb-2" target="_blank" rel="noopener noreferrer">
                <FaTwitter className="me-3" /> Accra, Ghana
              </a>
              <a href="tel:+233530504174" className="no-underline d-block mb-2">
                <FaFacebookF className="me-3" />+233 530 504 174
              </a>
              <a href="mailto:littlestarcharitygh@gmail.com" className="no-underline d-block">
                <FaEnvelope className="me-3" />littlestarcharitygh@gmail.com
              </a>
              {/* <a href="mailto:littlestarcharitygh@gmail.com" className="no-underline d-block">
                <FaLinkedinIn className="me-3" /> littlestarcharity
              </a> */}
            </div>

            <div className="col-lg-3 col-md-6">
              <h5 className="text-light mb-4 m-f">Quick Links</h5>
              <Link to="/about" className="no-underline d-block mb-2">
                <FaArrowRight className="me-3" />About Us
              </Link>
              <Link to="/contact" className="no-underline d-block mb-2">
                <FaArrowRight className="me-3" />Contact Us
              </Link>
              <Link to="/services" className="no-underline d-block">
                <FaArrowRight className="me-3" />Our Services
              </Link>
            </div>

            <div className="col-lg-3 col-md-6">
              <h5 className="text-light mb-4 m-f">Newsletter</h5>
              {/* Remove unnecessary comment */}
              {/* <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p> */}
              <div className="position-relative mx-auto" style={{ maxWidth: 400 }}>
                <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
              </div>
            </div>
          </div>
        </div>
        <hr className="footer-hr" />
        <div className="container-fluid copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-white text-md-start mb-3 mb-md-0">
                © <span href="#">2024</span> Little Star foundation. All Right Reserved.
              </div>
              <div className="col-md-6 text-center text-md-end">
                Powered by <a href="https://bo4fo.netlify.app" target="_blank" rel="noopener noreferrer" className=' no-underline'>bo4fo</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;