import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './impact.css';
import Loader from '../../Loader/loader'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock, faMapMarkerAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import project1 from '../../../Assets/Images/Projects/ProjectFeed250.jpg';
import project2 from '../../../Assets/Images/Projects/ProjectImpact.jpg';
import project3 from '../../../Assets/Images/Projects/finished-project-2.jpg';
import project4 from '../../../Assets/Images/Projects/finished-project-3.jpg';
import project5 from '../../../Assets/Images/Projects/Trinity Home Academy.png';

const ImpactPage = () => {
  const [loading, setLoading] = useState(true);
  const [projects] = useState([
    { id: 1, title: 'Project Feed 250', image: project1 },
    { id: 2, title: 'Project Impact', image: project2 },
    { id: 3, title: 'Potters Village Orphanage', image: project3 },
    { id: 4, title: ' New life Orphanage', image: project4 },
    { id: 5, title: 'Trinity Home Academy (Tutu)', image: project5 }
  ]);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(loadingTimeout);
  }, []);

  return (
    <div className="impact-page">
      {loading ? (
        <Loader />
      ) : (
        <div className="impact-page container">
          <div className="top-section">
            <h1 className="fw-bolder fs-8">Our Impact</h1>
            <p>Explore our completed projects that have made a significant difference in the communities we serve.</p>
          </div>

          <div className="project-list row">
            {projects.map((project, index) => (
              <div className="col-lg-3 col-md-6" key={project.id}>
                <div className="project-card">
                  <img src={project.image} alt={project.title} className="project-flyer" />
                  <div className="project-details">
                    <h3 className="fs-20 fw-bolder">{project.title}</h3>
                    {/* Only for the first project */}
                    {index === 0 && (
                      <div className="project-extra-details">
                        <div className="icon-text">
                          <FontAwesomeIcon icon={faCalendarAlt} /> <span>Coming Soon</span>
                        </div>
                        <div className="icon-text">
                          <FontAwesomeIcon icon={faClock} /><span>Time: Coming Soon</span>
                        </div>
                        <div className="icon-text">
                          <FontAwesomeIcon icon={faMapMarkerAlt} /> <span>Venue: Coming Soon</span>
                        </div>
                      </div>
                    )}

                    {/* Read More button with plus icon */}
                    <Link to={`/projects/${project.id}`} className="read-more-btn">
                      <FontAwesomeIcon icon={faPlus} />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImpactPage;
